<template>
  <div
    ref="formalScreen"
    class="formal-screen"
    :style="{ minHeight: dialogVisible ? '' : '100vh' }"
  >
    <div
      class="question-bg"
      :class="{ disabled: i.is_disabled }"
      :style="{ background: i.is_group ? i.block.group_info.bg_color : '' }"
      v-for="(i, index) in currentQuestion"
      :key="index"
    >
      <!-- 題組題資訊 -->
      <section v-show="i.is_group" :ref="i.block.group_info.group_id">
        <div style="font-size: 17px">
          {{ i.block.group_info.group_title }}
        </div>
        <div
          style="
            color: #979797;
            margin: 16px 0 24px 0;
            font-size: 14px;
            word-break: break-all;
          "
        >
          {{ i.block.group_info.group_description || '' }}
        </div>
      </section>

      <div class="question-card">
        <!-- 題目 -->
        <section
          v-for="k in i.block.question_array"
          :key="k.question_id"
          :ref="k.question_id"
          class="question"
          :class="{ disabled: k.is_disabled }"
        >
          <div class="subtitle">
            <span v-show="k.is_required" class="required">*</span>
            {{ k.title || '' }}
          </div>
          <div
            v-show="k.type === 3 || k.type === 13"
            style="
              color: #fff;
              width: 60px;
              text-align: center;
              font-size: 12px;
              background: #30a3d7;
              border-radius: 99px;
              margin-top: 10px;
            "
          >
            複選題
          </div>
          <img
            v-if="k.image !== null"
            v-lazy="k.image"
            style="width: 100%; margin-top: 16px"
            class="lazy-img"
          />
          <div style="padding-top: 16px">
            <component
              :is="getTemplate(k.type)"
              :question-block="i"
              :question-data="k"
              @handleSelected="handleSelected"
            ></component>
          </div>
        </section>
      </div>
    </div>
    <QuestionProgressBar
      :totalCount="current_question_info.total_question_count"
      :curEx="getCurQuesIndex"
    ></QuestionProgressBar>

    <div ref="btn" class="submit-btn">
      <el-button
        round
        style="background-color: rgb(48, 163, 215); color: #fff"
        @click="getNext"
      >
        {{ getButtonText }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import _findKey from 'lodash/findKey';
import { getStartQuestion, saveAnswer, doExit } from '@/api/targetQ';

const questionTypeIdMap = {
  QuestionSingle: 1,
  QuestionSingleVertical: 2,
  QuestionMutiple: 3,
  QuestionImage: 4,
  QuestionTrueOrFalse: 5,
  QuestionOnlyAns: 6,
  QuestionAddress: 7,
  QuestionDrop: 8,
  QuestionVideo: 9,
  QuestionRating: 10,
  QuestionEmail: 11,
  QuestionBirthday: 12,
  QuestionMutipleVertical: 13,
  QuestionInfo: 14,
};

export default {
  name: 'QuestionFormalScreen',
  props: {
    dialogVisible: { type: Boolean, default: false },
    screenType: { type: String, default: 'start' },
  },
  data() {
    return {
      current_question_info: [],
      required_question_list: [],
      answer_detail: {
        sort_index_start: 0,
        sort_index_end: 0,
        answer_list: [],
      },
      temp_jump_info: {},
    };
  },
  mounted() {
    // if (!this.dialogVisible) {
    //   this.getCurrentQuestion();
    // }
    this.getCurrentQuestion();
    console.log('mounted');
  },
  watch: {
    // 'answer_detail.answer_list.length'(newVal, oldVal) {
    //   //if (this.isStartFill) {
    //   this.setCurrentQuestionIndex(
    //     this.answer_detail.sort_index_start + newVal - 1
    //   );
    //   //}
    // },
  },
  computed: {
    ...mapGetters(['getAppUserToken', 'getCurQuesIndex']),
    currentQuestion() {
      return this.current_question_info.data;
    },
    getButtonText() {
      if (this.current_question_info.is_last_page) {
        return '送出';
      } else return '下一步';
    },
  },
  methods: {
    ...mapActions(['setCurrentQuestionIndex']),
    async getCurrentQuestion() {
      let res;
      //判斷是否是第一次進入問卷
      if (this.getCurQuesIndex === null) {
        res = await getStartQuestion(this.getAppUserToken);
      } else {
        res = await saveAnswer({
          ...this.getAppUserToken,
          ...{ answer_detail: this.answer_detail },
        });
        this.answer_detail.answer_list = [];
        this.required_question_list = [];
      }
      this.answer_detail.sort_index_start = res.sort_index_start;
      this.answer_detail.sort_index_end = res.sort_index_end;
      this.current_question_info = res;
      //檢查是否為最後一題
      if (this.current_question_info.is_finish) {
        this.handleSubmit();
      } else {
        this.setCurrentQuestionIndex(res.sort_index_start - 1);
        // set is_disabled for 跳題
        this.currentQuestion.forEach((i) => {
          // set 題組 or 單題外層
          this.$set(i, 'is_disabled', false);

          // set 題組 or 單題內層
          i.block.question_array.forEach((el) => {
            this.$set(el, 'is_disabled', false);
          });
        });
        this.calcRequired();
      }
      this.$refs.formalScreen.scrollIntoView({
        behavior: 'smooth',
      });
    },
    // 計算必填題目ＩＤ
    calcRequired() {
      this.required_question_list = [];
      this.currentQuestion.forEach((i) => {
        if (!i.is_disabled) {
          i.block.question_array.forEach((ques) => {
            // 題目為必填 && disableb false
            if (ques.is_required && !ques.is_disabled) {
              this.required_question_list.push(ques.question_id);
            }
          });
        }
      });
    },
    getTemplate(id) {
      return _findKey(questionTypeIdMap, (q) => {
        return q === id;
      });
    },
    handleSubmit() {
      if (this.dialogVisible) {
        this.$emit('update:screenType', 'end');
      } else {
        this.$router.replace({
          path: '/app-intro',
          query: { type: 'end', access_token: this.$route.query.access_token },
        });
      }
    },
    //驗證是否完成必填題目
    verifyRequired() {
      let verify = [];

      if (this.required_question_list.length !== 0) {
        if (this.answer_detail.answer_list.length !== 0) {
          let ans = this.answer_detail.answer_list.map((f) => f.question_id);

          for (let item in this.required_question_list) {
            if (ans.includes(this.required_question_list[item])) {
              verify.push(true);
            } else {
              verify.push(false);
            }
          }
        } else {
          verify.push(false);
        }

        // scroll to 未填的必填題目
        if (verify.some((v) => v === false)) {
          let errorQuestionId =
            this.required_question_list[verify.findIndex((f) => f === false)];
          this.$refs[errorQuestionId][0].scrollIntoView({
            behavior: 'smooth',
          });
        }
      } else {
        verify.push(true);
      }

      return verify.every((v) => v === true);
    },
    //驗證填答格式
    validatePattern() {
      let validateArr = [];
      this.answer_detail.answer_list?.forEach((ans) => {
        this.required_question_list?.forEach((id) => {
          if (ans.question_id === id) {
            validateArr.push(ans);
          }
        });
      });
      let validate = validateArr?.every((e) => e.validate);
      if (validateArr?.some((s) => !s.validate)) {
        let errorQuestionId = validateArr.find(
          (f) => f.validate === false
        ).question_id;
        this.$refs[errorQuestionId][0].scrollIntoView({
          behavior: 'smooth',
        });
      }

      return validate;
    },
    //下一題
    getNext() {
      if (!this.validatePattern()) {
        alert('填答格式有誤');
      } else {
        if (!this.verifyRequired()) {
          alert('您必須回答問題才能繼續');
        } else {
          this.getCurrentQuestion();
        }
      }
    },
    /**
     * @param question 題目data
     * @param ans 回答的答案選項(字串) 傳api用
     * @param questionBlock 完整題目data
     * @param selectedOption 回答的答案選項(object) 判斷跳題用
     */
    //處理選取題目答案
    handleSelected(question, ans, questionBlock, selectedOption, validate) {
      let answer = {
        sort_index_start: this.current_question_info.sort_index_start,
        sort_index_end: this.current_question_info.sort_index_end,
      };
      this.answer_detail = { ...this.answer_detail, ...answer };
      this.answer_detail.answer_list.forEach((i, index) => {
        if (i.question_id === question.question_id) {
          this.answer_detail.answer_list.splice(index, 1);
        }
      });
      this.answer_detail.answer_list.push({
        question_id: question.question_id,
        result: ans,
        validate: validate === undefined ? true : validate,
      });
      // 處理跳題
      if (selectedOption?.is_jump) {
        if (question.question_id === this.temp_jump_info.question_id) {
          this.currentQuestion.forEach((i) => {
            i.is_disabled = false;

            i.block.question_array.forEach((el) => {
              el.is_disabled = false;
            });
          });
        }
        // 當下回答題目Index
        let currentIndex = 0;
        currentIndex = this.currentQuestion.findIndex(
          (block) => block === questionBlock
        );

        let jumpToTargetPosition = this.$refs[selectedOption.jump_to];
        // 判斷要跳題的題目是否在同一頁 handle disabled sytle
        if (jumpToTargetPosition) {
          jumpToTargetPosition[0].scrollIntoView({
            behavior: 'smooth',
          });

          // find 要跳題的題目
          let jumpToTarget = {};
          let jumpToIndex = 0;
          for (let i = 0; i < this.currentQuestion.length; i++) {
            // 判斷要跳的題目類型 1:題目 2:題組
            if (
              selectedOption.jump_type === 1 &&
              !this.currentQuestion[i].is_group
            ) {
              if (
                this.currentQuestion[i].block.question_array.find(
                  (f) => f.question_id === selectedOption.jump_to
                )
              ) {
                jumpToTarget = this.currentQuestion[i];
                jumpToIndex = i;

                break;
              }
            } else if (
              selectedOption.jump_type === 2 &&
              this.currentQuestion[i].is_group
            ) {
              if (
                this.currentQuestion[i].block.group_info.group_id ===
                selectedOption.jump_to
              ) {
                jumpToTarget = this.currentQuestion[i];
                jumpToIndex = i;

                break;
              }
            }
          }

          this.currentQuestion.forEach((el, index) => {
            // disabled 當下回答的題目=>要跳的題目
            if (index >= currentIndex && index < jumpToIndex) {
              el.is_disabled = true;
            }
          });
          //判斷當下題目是否為題組
          if (questionBlock.is_group) {
            //disabled題組內其他題目
            currentIndex = questionBlock.block.question_array.findIndex(
              (f) => f.question_id === question.question_id
            );
            questionBlock.block.question_array.forEach((q, index) => {
              if (index > currentIndex) {
                q.is_disabled = true;
              }
            });
          }
          console.log('>>>>>>>', currentIndex, jumpToIndex);
        } else {
          // scroll to bottom
          //判斷當下題目是否為題組
          this.currentQuestion.forEach((i, index) => {
            // disabled 非題組當下回答的題目=>要跳的題目
            if (index > currentIndex) {
              i.is_disabled = true;
            }

            if (questionBlock.is_group) {
              //disabled題組其他題目
              currentIndex = questionBlock.block.question_array.findIndex(
                (f) => f.question_id === question.question_id
              );
              questionBlock.block.question_array.forEach((q, index) => {
                if (index > currentIndex) {
                  q.is_disabled = true;
                }
              });
            }
          });

          this.$refs.btn.scrollIntoView({
            behavior: 'smooth',
          });
        }
        //紀錄當下回答的題目
        this.temp_jump_info = question;
        // 當下回答的題目(block)
        questionBlock.is_disabled = false;
        this.calcRequired();
      } else if (question.question_id === this.temp_jump_info.question_id) {
        this.currentQuestion.forEach((i) => {
          i.is_disabled = false;

          i.block.question_array.forEach((el) => {
            el.is_disabled = false;
          });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: 17px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
  white-space: normal;
  word-break: break-word;
}

.formal-screen {
  position: relative;
  width: 100%;
  background-color: #f7f7f7;
  font-family: PingFangTC-Regular;
}

.submit-btn {
  text-align: center;
  padding: 5%;
  .el-button {
    width: 100%;
  }
}

.question-bg {
  padding: 20px 14px;
  background: #f7f7f7;
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }
}

.question-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 1px 0 #dadada;
  padding: 12px 14px;
}

.question {
  & > * {
    line-height: normal !important;
    letter-spacing: normal;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

/deep/ .el-radio__label,
/deep/ .el-checkbox__label {
  font-size: 17px;
  font-weight: normal;
  white-space: normal;
  line-height: normal;
  vertical-align: middle;
  display: inline-block;
  text-align: justify;
  word-break: break-all;
}

.disabled {
  filter: opacity(0.5);
  pointer-events: none;
}

/deep/ .el-radio.is-bordered,
/deep/ .el-checkbox.is-bordered {
  height: auto;
  padding: 12px 20px 12px 10px;
}

/deep/ .el-button {
  font-weight: 500 !important;
}
</style>
